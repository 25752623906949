import React, { } from 'react';
import { StyleSheet, Text } from 'react-native'


export const bftToKts = {
  0: 0,
  1: 1,
  2: 4,
  3: 7,
  4: 11,
  5: 17,
  6: 22,
  7: 28,
  8: 34,
  9: 41,
  10: 48,
  11: 56,
  12: 63,
};

export const windUnitList = {
  kts: {
    factor: 1,
    text: ' kts'
  },
  bft: {
    factor: 1,
    text: ' bft'
  },
  kph: {
    factor: 1.852,
    text: ' km/h'
  },    
  mps: {
    factor: 0.5144,
    text: ' m/s'
  },
}

export const findBft = (kts) => {
  for ( let bft = 0; bft < 12; bft++ ) {
    if ( kts < bftToKts[bft] ) {
      return bft - 1;
    }
  }
  return 12
}

export function WindUnit( props ) {
  if (props.id !== 'detailView' || !( props.variable === 'Wind' || props.variable === 'Gusts' ) ) {
    return null;
  }

  const changeUnitID = () => {
    const OldUnitID = props.unitID;
    props.setUnitID( OldUnitID === Object.keys(windUnitList).length - 1 ? 0 : OldUnitID + 1 )
    localStorage.setItem('unitID', OldUnitID === Object.keys(windUnitList).length - 1 ? 0 : OldUnitID + 1)
  }

  return (
    <>
      <Text style={styles.unitText} onClick={changeUnitID}> unit:<b>{ windUnitList[Object.keys(windUnitList)[props.unitID]]['text']  }</b> </Text>
    </>
  );
}

const styles = StyleSheet.create({
  unitText: {
    position: 'absolute',
    top: 0,
    right: '1%',
    padding: '1%',
    fontSize: 15,
    userSelect: "none",
  },
});