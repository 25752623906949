import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import moment from 'moment';

import DensityChart from './DensityChart';
import { findBft, windUnitList} from './WindUnit';

export function TickLine(props) {
  if ( props.positionX < 0 ) {
    return null;
  }

  const tickWidth = 1 / props.size;
  var linePosition = String( props.positionX * tickWidth * 100) + '%';
  var smallLinePosition = String( props.positionX * tickWidth * 100 ) + '%';

  if ( props.deviceType === 'mobile' && props.id === 'detailView' ) {
    linePosition = '50%'
  }

  return (
  <View style={ [ StyleSheet.absoluteFill, {pointerEvents: 'none', flex: 1} ] }>
    <View style={[ 
      styles.tickLine, 
      ( props.id === 'detailView' ) ? [ styles.tickLineLarge, { left: linePosition }] : [ styles.tickLineSmall, { left: smallLinePosition } ],
      ]}></View>
  </View>
  );
};

export function TickCircle(props) {
  if ( props.positionX < 0 ||  props.id !== 'detailView' ) {
    return null;
  }

  const tickWidth = props.viewWidth / props.size;
  var positionLeft = props.positionX * tickWidth;

  if ( props.deviceType === 'mobile' ) {
    positionLeft = '50%'
  }

  var circleOffset = 9 + 5;

  if ( props.viewWidth > 2000 ) {
    circleOffset = 9.5 + 5;
  }
  
  const positionBottom = String ( props.data['maxprob'][props.positionX ] / props.data['highest_point'] * 100 * 0.85 + circleOffset) + '%';

  return (
    <View style={ [ styles.circle, { left: positionLeft, bottom: positionBottom }]}/>
  )
}

export function TickText(props) {

  if ( props.positionX < 0 ||  props.id !== 'detailView' ) {
    return null;
  }

  // determine positons from right and bottom of view
  const tickWidth = props.viewWidth / props.size;
  var positionRight = props.viewWidth - props.positionX * tickWidth + 10
  if ( positionRight > props.viewWidth - 80 ) {
    positionRight = positionRight - 85
  }

  if ( props.deviceType === 'mobile' ) {
    positionRight = '51%'
  }

  const positionBottom = String ( props.data['maxprob'][props.positionX ] / props.data['highest_point'] * 100 * 0.85 + 9) + '%';

  // determine value and unit to be shown
  if ( props.title === 'Wind direction' ) {

    const directions = [ 'N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N' ]

    var degrees = Math.round(props.data['maxprob'][props.positionX]-(props.data['offset']));
    var directionIndex = Math.round(degrees / 360 * 8);

    var variableText = directions[directionIndex] + ' ' + degrees + props.unit

  } else if ( props.title === 'Wind' || props.title === 'Gusts' ) {
    var windSpeed = Math.round( (props.data['maxprob'][props.positionX]-props.data['offset']) * windUnitList[props.unit]['factor'] );

    if ( props.unit === 'bft' ) {
      windSpeed = findBft(windSpeed)
    }

    variableText = String( windSpeed ) + windUnitList[props.unit]['text']

  } else {
    variableText = String( Math.round(props.data['maxprob'][props.positionX]-(props.data['offset'])) ) + props.unit
  }

  return (
    <View style={ [ styles.textBox, { right: positionRight, bottom: positionBottom }]}>
      <Text style={{ fontSize: 20 }} >  
        { moment.utc( props.date_hour ).local().add( props.positionX / 79 * 237, 'hours').startOf('hour').format('HH:mm') }
        {"\n"}
        { variableText }
      </Text>
    </View>
  )
}

export function TickGraph(props) {
  if ( props.positionX < 0 ||  props.id !== 'detailView' || props.clicked === false || props.title === 'Precipitation' || props.title === 'Cloud cover' ) {
    return null;
  }

  const tickWidth = props.viewWidth / props.size;
  var linePosition = props.positionX * tickWidth + 10;
  var boxWidth = 20;

  if ( props.deviceType === 'mobile' ) {
    linePosition = '51%'
    boxWidth = 30;
  }

  const timeStep = String(props.positionX * 3 + 3).padStart(3, "0")

  const boxWidthPercentage = Math.max(...props.data['densities'][timeStep]) / props.data['max_density'] * props.viewWidth*boxWidth/100 + 10

  return (
    <View style={ [ styles.graphBox, { width: boxWidthPercentage, left: linePosition } ] }>
        <DensityChart 
          data={props.data['densities'][timeStep]}
          width={props.viewWidth*boxWidth/100}
          height={props.viewHeight*0.85}
          gridMax={props.data['max_density']}
          color={props.color}
        />
        <Text style={{fontSize: 17, padding: 10}}>Probability</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  graphBox: {
    userSelect: "none",
    flex: '1 1 100%',
    flexDirection: 'row',
    padding: 5,
    height: '85%',
    borderRadius: 10,
    position: 'absolute',
    bottom: '15%',
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    pointerEvents: 'none',
    // justifyContent: 'flex-end'
  },
  circle: {
    flex: 1,
    position: 'absolute',
    marginLeft: -4,
    width: 10,
    height: 10,
    borderRadius: 100 / 2,
    borderWidth: 2,
    borderColor: 'black',
    backgroundColor: "white"
  },
  textBox: {
    userSelect: "none",
    flex: 1,
    padding: 5,
    borderRadius: 10,
    position: 'absolute',
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    pointerEvents: 'none',
  },
  variableText: {
    flex: 1,
    position: 'absolute',
    fontSize: 20, 
    textAlign: 'right'
  },
  tickLine: {
    borderColor: 'rgba(0,0,0, 0.2)',
    height: '85%',
    width: 1,
  },
  tickLineLarge: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderRadius: 1,
  },
  tickLineSmall: {
    borderWidth: 1
  }
});
