import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import moment from 'moment';

export default function chartDays( props ) {

  const days = [];

  const hour_width = 1 / 237;
  const first_day_hours = 9 - parseInt(props.hour);
  const first_tick_hours = 13 - parseInt(props.hour);

  for( let i = 0; i < 10 ; i++ ) {

    const positionDay = String ( ( first_day_hours * hour_width + i * 24 * hour_width ) * 100 - 0.8 ) + '%'
    const positionTick = String ( ( first_tick_hours * hour_width + i * 24 * hour_width ) * 100 - 0.8 ) + '%'

    days.push(
      <>
      { ( props.isDetailView === 'detailView' )
      ? <View style={ [ StyleSheet.absoluteFill,  { width: 55, left: positionDay, justifyContent: 'center'} ] } >
          <Text style={ styles.dayText }>{ moment( props.day ).add(i, 'days').format('ddd \n DD-MM') } </Text>        
        </View>
      :
      <View style={ [ StyleSheet.absoluteFill,  { width: 5, left: positionTick, justifyContent: 'center'} ] } >
        <Text style={[ 
          ( props.deviceType === 'desktop' ) ? styles.lineTextLarge : styles.lineTextSmall,
          { marginLeft: 0 }]}>l</Text>
      </View> 
      }
      </>
    )
  }

  return (
    <View style={ [ StyleSheet.absoluteFill, {top: 0, flex: 1 }]}>
      { days }
    </View>
  );
}

const styles = StyleSheet.create({
  dayText: {
    userSelect: "none",
    textAlign: 'center',
    fontSize: 18,
    color: '#9E9E9E',
  },
  lineTextSmall: {
    fontSize: 4
  },
  lineTextLarge: {
    fontSize: 7
  }
});