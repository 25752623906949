import * as React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { findBft, bftToKts, windUnitList } from './WindUnit';

function GridlinesComponent (posText, posLine, text) {
  return (
    <View style={[ StyleSheet.absoluteFill, {flex: 1, flexDirection: 'row', height: '85%'} ]}>
      <View style={{ left: 5, paddingRight: 10, top: posText }}> 
        <Text style={ styles.yText } >
          { text }
        </Text>
      </View>
      <View style={[ styles.gridline, { top: posLine } ] }></View>
    </View>
  )
}

function GridlinesClouds() {

  const coverText = [ 'Cloudy', 'Sunny' ]
  const lines = [];

  for( let i = 0; i < 2; i++ ) {

    const posText = String ( 99 * i - 1) + '%';
    const posLine = String ( 99 * i + 1) + '%';

    lines.push(
      GridlinesComponent(posText, posLine, coverText[i] )
    )
  }

  return (
    lines
  )
}

function GridlinesWinddirection() {

  const directions = [ 'N', 'NW', 'W', 'SW', 'S', 'SE', 'E', 'NE', 'N' ]
  const lines = [];

  for( let i = 0; i < 9; i++ ) {

    const posText = String ( 12.375 * i - 1) + '%';
    const posLine = String ( 12.375 * i + 1) + '%';

    lines.push(
      GridlinesComponent(posText, posLine, directions[i] )
    )
  }
  
  return (
    lines
  )
}

function GridlinesBft(ktsMax) {

  ktsMax = Math.floor(ktsMax)
  const bftMax = findBft(ktsMax);
  const percentPerKt = 100 / ktsMax;

  const lines = [];

  for( let bft = 1; bft <= bftMax; bft++ ) {

    const ktsLine = bftToKts[bft];

    const posText = String ( 100 - percentPerKt * ktsLine - 1) + '%';
    const posLine = String ( 100 - percentPerKt * ktsLine + 1) + '%';

    lines.push(
      GridlinesComponent(posText, posLine, String(bft) + ' bft' )
    )
  }
  
  return (
    lines
  )
}

function GridLinesDefault(varMin, varMax, symbol) {
  // first find first and last value in range, rounded to 5
  const firstLine = Math.round(varMin) - (Math.round(varMin)%5)
  const lastLine = Math.round(varMax) - Math.abs(Math.round(varMax)%5)

  if ( lastLine - firstLine > 70 ) {
    var interval = 20

  } else if ( lastLine - firstLine > 25 ) {
    interval = 10

  } else {
    interval = 5
  }

  const lines = [];

  const range = Math.abs(varMax - varMin)

  for( let i = firstLine; i <= lastLine; i=i+interval) {

    var numberFromMax = Math.abs(varMax - i)

    var posText = String ( numberFromMax / range * 100 - 3 ) + '%';
    var posLine = String ( numberFromMax / range * 100) + '%';
    const text = String(i) + symbol

    lines.push(
      GridlinesComponent(posText, posLine, text )
    )
  }
  
  return (
    lines
  )
}

export default function Gridlines( varMin, varMax, symbol, title, isDetail, unitID) {

  if ( isDetail !== 'detailView' ) {
    return null;

  } else if ( title === 'Wind direction' ) {
    return GridlinesWinddirection( )

  } else if ( title === 'Cloud cover' ) {
    return GridlinesClouds( )
    
  } else if ( title === 'Gusts' || title === 'Wind' ) {
    const windUnit = Object.keys(windUnitList)[unitID]

    if ( windUnit === 'bft' ) {
      return GridlinesBft(varMax)
    }
    
    symbol = windUnitList[windUnit]['text']
    varMax = varMax * windUnitList[windUnit]['factor']
  }

  if ( title !== 'Temperature' ) {
    varMin = 0;
  }

  return GridLinesDefault(varMin, varMax, symbol)
}

const styles = StyleSheet.create({
    gridline: {
      flex: 1,
      borderColor: 'rgba(214,212,212, 0.7)',
      height: 0.2,
      borderWidth: 0.2,
      borderStyle: 'dashed',
      borderRadius: 1,
    },
    yText: {
      textAlign: 'left',
      fontSize: 18,
      color: '#9E9E9E'
    },
  });