import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Pressable, ImageBackground } from 'react-native';

import ChartSrc from '../data/nl_chart.png';

// render the image and texts, add rows that contain tiles using renderTiles 
export default function LocationPicker({location, setLocation }) {

  const [activeTile, setActiveTile] = useState([( location.lon - 3 )/0.25, (12 - (location.lat - 50.75) / 0.25)]);

  
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const windowRef = useRef()

  console.log(activeTile);
  useEffect(() => {

    function handleResize() {

      const aspectRatio = 808 / 933;

      const currentWidth = windowRef.current.getBoundingClientRect().width;
      const currentHeight = windowRef.current.getBoundingClientRect().height;

      if ( currentWidth / currentHeight < aspectRatio )  {
        setWidth(currentWidth);
        setHeight(currentWidth / aspectRatio);
      } else {
        setWidth( currentHeight * aspectRatio);
        setHeight( currentHeight );
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);

  }, []); //empty dependency array so it only runs once at render

  const rows = [];
  for( let i = 0; i < 13; i++) {
    rows.push(
      renderTiles(setLocation, i, activeTile, setActiveTile)
    )
  }

  return (
    <View ref={windowRef} style={{ flex: '1 1 100%', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <ImageBackground style={ {width: width, height: height, justifyContent: 'center'  }}
        source={ { uri: ChartSrc } }
        resizeMode='contain'
        >
        <View style={ {height: height, width: width, flexDirection: 'column'}} >
          { rows }
        </View>
      </ImageBackground>
    </View>
  )
}

// Use this function to render a row of tiles 
function renderTiles(setLocation, iVertical, activeTile, setActiveTile) {
  const buttons = [];
  for( let i = 0; i < 18; i++) {

    buttons.push(
      <Pressable 
        style={({ pressed }) => [
          styles.mapButton,
          ( ( i===activeTile[0] && iVertical===activeTile[1] ) || pressed )  ? styles.tileActive : styles.tileInactive
        ]}
        onPress={() => { setLocation({ lat: 53.75 - iVertical * 0.25 , lon: 3 + i * 0.25}); setActiveTile( [ i, iVertical ])} }>
      </Pressable>
    )
  }
  return (
    <View style={{flex: 1, flexDirection: 'Row', justifyContent: 'center'}}>
      { buttons }
    </View>
  );
}

const styles = StyleSheet.create({
  mapButton: {
    justifyContent: 'center', 
    height: '100%', 
    width: '5.556%',
  },
  tileInactive: {
    borderWidth: 1, 
    borderColor: 'black',
    opacity: 0.1,
  },
  tileActive: {
    borderWidth: 2, 
    opacity: 0.9,
    borderColor: '#CE0000'
  }
});