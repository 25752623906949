import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import moment from 'moment';

export default function ChartTimeOverlay( startDay, startHour, id ) {

  if ( id !== 'detailView' ) {
    return null;
  }

  const boxes = [];
  const hour = ( '0' + startHour).slice(-2);
  
  const hourWidth = 1 / 237;
  const firstBoxHours = 18 - parseInt( startHour );
  var nightLength = 14;

  const totalBoxes = Math.floor(( 240 - firstBoxHours ) / 24)


  const dateHourGeneration = startDay + ' ' + hour;
  const now = moment.utc(new Date()).local();
  const end = moment.utc( dateHourGeneration ).local();

  const timeDifference = now.diff(end, 'hours');

  const nowLinePosition = String( timeDifference * hourWidth * 100 ) + '%';

  for( let i = 0; i < totalBoxes + 1; i++ ) {

    if ( firstBoxHours + 24 * i + nightLength > 237 ) {
      nightLength = 237 - ( firstBoxHours + i * 24 );
    }

    const boxWidth = String( nightLength * hourWidth * 100 ) + '%';

    const boxPosition = String( (firstBoxHours * hourWidth + 24 * i * hourWidth ) * 100 ) + '%';

    boxes.push(
      <View style={[ StyleSheet.absoluteFill, styles.nightBox, { width: boxWidth , left: boxPosition } ]}></View>
    )
  }
  
  return (
    <View style={ [ StyleSheet.absoluteFill, {flex: 1} ] }>
      <View style={ [ StyleSheet.absoluteFill, {flex: 1, flexDirection: 'row' } ] }>
        { boxes }
      </View>
      <View style={[ styles.nowLine, { left: nowLinePosition } ] }></View>
    </View>
  )
}

const styles = StyleSheet.create({
    nightBox: {
      backgroundColor: 'rgba(0,0,0, 0.03)',
    },
    nowLine: {
      borderColor: 'rgba(255,0,0, 0.2)',
      height: '100%',
      width: 1,
      borderWidth: 2,
      borderStyle: 'dashed',
      borderRadius: 1,
    },
  });