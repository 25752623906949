import React from 'react';
import { StyleSheet } from 'react-native';
import { Path, Defs, LinearGradient, Stop } from 'react-native-svg'
import { AreaChart } from 'react-native-svg-charts'
import * as shape from 'd3-shape'

export default function DensityChart( props ) {
  const maxColor =  Math.max(...props.data) / props.gridMax * 0.6 + 0.15

  const Line = ({ line }) => (
    <Path
      key={'line'}
      d={line}
      stroke={'rgb(0,0,0)'}
      fill={'none'}
    />
  );

  const Gradient = ({ index }) => (
    <Defs key={index}>
      <LinearGradient id={'gradient'} x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
        <Stop offset={'0%'} stopColor={props.color} stopOpacity={maxColor}/>
        <Stop offset={'100%'} stopColor={props.color} stopOpacity={0.15}/>
      </LinearGradient>
    </Defs>
)

  return (
    <AreaChart
      style={[StyleSheet.absoluteFill, { height: props.width, width: props.height, transform: [ {translateY: props.height-props.width*0.5}, {translateX: -props.height/2}, {rotate: '90deg'}, { scaleX: -1 }, {translateX: props.height/2}, {translateY: -props.width/2} ]}]}
      data={props.data}
      horizontal={true}
      curve={shape.curveMonotoneX}
      svg={{ fill: 'url(#gradient)' }}
      gridMax={props.gridMax}
    >
      <Line/>
      <Gradient/>
    </AreaChart>  
  );
};