import React, { useEffect, useState, useCallback } from 'react';
import {PanResponder, Text, ScrollView, View, StyleSheet, Dimensions } from 'react-native';
import { LineChart, StackedAreaChart } from 'react-native-svg-charts';
import * as shape from 'd3-shape';

import ChartDays from './ChartDays';
import ChartTimeOverlay from './ChartTimeOverlay';
import Gridlines from './Gridlines';
import { TickCircle, TickGraph, TickLine, TickText } from './TickComponents'
import { WindUnit, windUnitList } from './WindUnit';

export default function GenericChart( props ) {
  const [viewWidth, setViewWidth] = useState(0);
  const [viewHeight, setViewHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [deviceType, setDeviceType] = useState('desktop');
  const [clicked, setClicked] = useState(false);
  const [unitID, setUnitID] = useState(0);

  const setGlobalPositionX = props.setGlobalPositionX;

  const fetchStorage = useCallback(() => {
    try {
      const storedUnitID = JSON.parse(localStorage.getItem('unitID'))
      if ( storedUnitID !== null ) {
        setUnitID(storedUnitID)
      }
    } catch (error) {
      console.log(error)
    }
  }, []);

  useEffect(() => {
    fetchStorage();
  }, [fetchStorage]);

  useEffect(() => {
    var windowWidth = 0;

    function handleResize() {
      if (props.id === 'detailView') {
        // only update sizes if size has actually changed
        if ( windowWidth !== Dimensions.get('window').width ) {
          setViewWidth(props.viewRef.current.getBoundingClientRect().width);
          setViewHeight(props.viewRef.current.getBoundingClientRect().height);
          setWindowWidth(Dimensions.get('window').width);

          windowWidth = Dimensions.get('window').width;

          if ( Dimensions.get('window').width > 1000 ) {
            setDeviceType('desktop');
          }

          if ( Dimensions.get('window').width <= 1000 ) {
            setDeviceType('mobile');
          }
        }
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);

  }, [props.viewRef, props.id]); //empty dependency array so it only runs once at render

  const size = 79;

  const handleClick = () => {
      setClicked(!clicked);
  }

  // on mobile respond to release of click, on desktop respond to start of click
  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: (evt, gestureState) => true,
    onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
    onMoveShouldSetPanResponder: (evt, gestureState) => true,
    onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
    onPanResponderTerminationRequest: (evt, gestureState) => true,

    onPanResponderGrant: (evt, gestureState) => {
      if ( deviceType === 'desktop' ) {
        handleClick()
      } 
      return true;
    },
    onPanResponderMove: (evt, gestureState) => { 
      return true;
    },
    onPanResponderRelease: () => {
      if ( deviceType === 'mobile' ) {
        handleClick()
      } 
    },
  });

  const handleScroll = (event) => { 
    const newPosition = Math.max( Math.min( Math.round(event.nativeEvent.contentOffset.x / 1000 * size ), 79 ) , 0 )

    if ( props.id === 'detailView' && newPosition !== props.globalPositionX ) {
      setGlobalPositionX( newPosition )
    }  
  }

  const updatePosition = (x) => {
    if ( deviceType === 'mobile' || props.id !== 'detailView' ) {
      return null
    }

    const x0 = 0;// x0 position
    const chartWidth = viewWidth - x0;
    const xN = x0 + chartWidth;//xN position
    const xDistance = chartWidth / size;// The width of each coordinate point
    if (x <= x0) {
        x = x0;
    }
    if (x >= xN) {
        x = xN;
    }

    let value = ((x - x0) / xDistance).toFixed(0);
    if (value >= size) {
        value = size; // Out of chart range, automatic correction
    }

    props.setGlobalPositionX(Number(value));
  };

  const gridmin = 0;
  const gridmax = props.data['highest_point'];

  const keys = [0, 1];
  const svgs = {};

  const content_inset = { top: 0.5, bottom: 0.5 };

  var minWidth = 0;
  var leftInset = 0;
  var rightInset = 0;

  if ( props.id === 'detailView' && deviceType === 'mobile' ) {
    minWidth = 1000;
    leftInset = windowWidth / 2;
    rightInset = windowWidth / 2;
  }
  
  return (
    <>
    <Text style={styles.variableText}> { props.data['chart']['title']}</Text>
    <WindUnit
        variable={props.data['chart']['title']}
        id={props.id}
        unitID={unitID}
        setUnitID={setUnitID}
      />
    <View style={{ userSelect: "none", paddingTop: 0, top: '5%', height: '90%', flex: '1 1 100%'}}>
      { Gridlines(props.data['var_min'], props.data['var_max'], props.data['chart']['unit'], props.data['chart']['title'], props.id, unitID) }
      <ScrollView 
        bounces={false}
        alwaysBounceHorizontal={false}
        horizontal={true} 
        ref={props.viewRef}
        onScroll={handleScroll}
        scrollEventThrottle={9}
        contentContainerStyle={{flexGrow: 1, flexDirection: 'column', paddingLeft: leftInset, paddingRight: rightInset }}>
        <View 
          onMouseEnter={ e => { updatePosition(e.clientX-16) }}
          onMouseMove={ e => { updatePosition(e.clientX-16) }}
          onMouseOver={ e => { updatePosition(e.clientX-16) }}
          onMouseLeave={() => { if ( deviceType === 'desktop' && props.id === 'detailView' ) {
            props.setGlobalPositionX(-1) }}
          }
          style={{width: '100%', height: '85%', minWidth: minWidth }} {...panResponder.panHandlers}
        >
          <StackedAreaChart 
            style={ StyleSheet.absoluteFill}
            data={props.data['baselayer']}
            svg={svgs}
            keys={keys}
            colors={props.data['chart']['color1']}
            gridMin={gridmin}
            gridMax={gridmax}
            showGrid={false}
            contentInset={content_inset}
            curve={shape.curveMonotoneX}
          />
          <StackedAreaChart
            style={ StyleSheet.absoluteFill}
            data={props.data['layer2']}
            svg={svgs}
            keys={keys}
            colors={props.data['chart']['color2']}
            gridMin={gridmin}
            gridMax={gridmax}
            showGrid={false}
            contentInset={content_inset}
            curve={shape.curveMonotoneX}
          />
          <StackedAreaChart
            style={ StyleSheet.absoluteFill}
            data={props.data['layer3']}
            svg={svgs}
            keys={keys}
            colors={props.data['chart']['color3']}
            gridMin={gridmin}
            gridMax={gridmax}
            showGrid={false}
            contentInset={content_inset}
            curve={shape.curveMonotoneX}
          />
          <LineChart
            style={ StyleSheet.absoluteFill}
            data={props.data['maxprob']}
            gridMin={gridmin}
            gridMax={gridmax}
            svg={{ strokeWidth:"1.5", stroke: 'rgba(0, 0, 0, 0.7)' }}
            contentInset={content_inset}
            curve={shape.curveMonotoneX}
          />
          { ChartTimeOverlay( props.day, props.hour, props.id ) }
        </View>
        <View style={{ flex: '1 1 100%' }}>
          <ChartDays day={props.day} hour={props.hour} isDetailView = {props.id} deviceType={deviceType} />
        </View>
      </ScrollView>
      <TickLine
        positionX={props.globalPositionX}
        viewWidth={viewWidth}
        windowWidth={windowWidth}
        id={props.id}
        size={size}
        deviceType={deviceType}
      />
      <TickCircle
        positionX={props.globalPositionX}
        viewWidth={viewWidth}
        id={props.id}
        size={size}
        deviceType={deviceType}
        data={props.data}
      />
      <TickGraph
        positionX={props.globalPositionX}
        viewWidth={viewWidth}
        viewHeight={viewHeight}
        id={props.id}
        size={size}
        deviceType={deviceType}
        data={props.data}
        title={props.data['chart']['title']}
        date_hour={props.date_hour}
        color={props.data['chart']['color_default']}
        clicked={clicked}
      />
      <TickText 
        positionX={props.globalPositionX}
        viewWidth={viewWidth}
        viewHeight={viewHeight}
        id={props.id}
        size={size}
        deviceType={deviceType}
        data={props.data}
        unit={props.data['chart']['title'] === 'Wind' || props.data['chart']['title'] === 'Gusts' ? Object.keys(windUnitList)[unitID] : props.data['chart']['unit']}
        title={props.data['chart']['title']}
        date_hour={props.date_hour}
      />
    </View>   
    </>
  );
}

const styles = StyleSheet.create({
  variableText: {
    position: 'absolute',
    top: 0,
    fontSize: 20,
    padding: '1%',
    borderRadius: 10,
  },
});