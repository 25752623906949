import React, { useCallback, useEffect, useState, useRef } from 'react'
import './app.css';
import logo from './logo.png';
import GenericChart from './components/GenericChart';
import LocationPicker from './components/LocationPicker';
import { ActivityIndicator, View } from 'react-native';
import { delay } from 'lodash';

// potential data that needs to be included in the api response.
const charts = [
  'windspeed',
  'temp',
  'winddirection',
  'rain',
  'clouds',
  'gust'
];

export default function App() {
  const [detailView, setDetailView] = useState(charts[0]);
  const [location, setLocation] = useState({lat: '52.25', lon: '5.25'});
  const [locationLoaded, setLocationLoaded] = useState(false)
  const [state, setState] = useState({});
  const [globalPositionX, setGlobalPositionX] = useState(0);// The currently selected X coordinate position
  const mapRef = useRef(null);
  const viewRef = useRef(null);
  const viewRefSmall = useRef(null);

  const scrollInView = () => {
    if ( globalPositionX > 0 ) {
      viewRef.current.scrollTo({ x: globalPositionX/79*1000,
        animated: false });
    }
  };

  const fetchStorage = useCallback(() => {
    try {
      const storedLocation = JSON.parse(localStorage.getItem('location'))
      if (storedLocation !== null) {
        setLocation(storedLocation)
      }
    } catch (error) {
      console.log(error)
    }
    setLocationLoaded(true)
  }, []);

  const fetchData = useCallback(() => {
    if ( locationLoaded ) {
      fetch(
        'https://europe-west1-weerbeeld.cloudfunctions.net/handle-mvp-request/?lat=' + location.lat + '&lon=' + location.lon, 
        {method: 'GET'}
      )
      .then(response => response.json())
      .then((response) => setState({data: response, dataLoaded: true}))
      .then(() => delay(scrollInView, 100))
      .catch((error) => {
        console.error(error);
      });
    }
    // added next line because fetchData should not rerender on a change in scrollInView
    // eslint-disable-next-line 
  }, [location, locationLoaded]);

  const changeDetailView = (chartKey, positionX) => {
    setDetailView(chartKey);
    setGlobalPositionX(positionX);
    delay(scrollInView, 200)
  }

  const changeLocation = (props) => {
    setLocation(props);
    localStorage.setItem('location', JSON.stringify(props))
    setState(prevState => ({...prevState, dataLoaded: false}));
  }
  
  const scrollToMap = () => mapRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })   

  const getGraph = (index, chartName, isDetail = false) => {
    const className = 'item content-' + (isDetail ? 'detail' : index) + ' ' + (chartName === detailView && 'active');
    return (
      <div key={chartName + index} className={className} id={chartName} onClick = {() => changeDetailView(chartName, globalPositionX)}>
        {state.dataLoaded 
          ? <GenericChart
              key={chartName + index}
              id = {isDetail ? 'detailView' : chartName}
              index = {index}
              day = { state.data['day'] }
              hour = { state.data['hour'] }
              date_hour = {state.data['day'] + ' ' + ( '0' + state.data['hour']).slice(-2)} 
              data = {state.data[chartName]} 
              globalPositionX = {globalPositionX}
              setGlobalPositionX = {setGlobalPositionX} 
              viewRef={isDetail? viewRef : viewRefSmall}
            />
          : 
          <View style={{ flex: '1 1 100%', justifyContent: 'center', alignItems: 'center', marginTop: -30 }}>
            <ActivityIndicator size="large" color="#006eaa" />
          </View>
        }
        
      </div>
    )
  }

  const decryptEmail = (encoded) => {
    var address = atob(encoded);
    return "mailto:" + address;
  }

  useEffect(() => {
    fetchStorage();
  }, [fetchStorage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
    <div class="header">
      <img src={logo} alt="Logo" class="logo" />
      <h3>Weather Driven</h3>
      <div class="header-text" onClick={scrollToMap}>
        <p>
          lat: {location.lat}
          {"\n"}
          lon: {location.lon} 
        </p>
      </div>
    </div>
    <div class="container">
      {getGraph(1, detailView, true)}
      {charts.map((chartName, index) => {
        return getGraph(index+1, chartName);
      })}
      <div ref={mapRef} class="item map">
        <span class="mapTitle">
          Location
        </span>
        { locationLoaded || state.dataLoaded
        ? <LocationPicker
            location={location}
            setLocation={changeLocation}  
          />
        : 
        <View style={{ flex: '1 1 100%', justifyContent: 'center', alignItems: 'center', marginTop: -30 }}>
          <ActivityIndicator size="large" color="white" />
        </View>
        }
      </div>
      <div class="item footer">
        <h5>
          Welcome to <i>WEDR</i> - enabling weather driven decision making for everyone. 
        </h5>
        <p>
          Make your own weather forecast, and get a clear sense of (un)certainty. The darker and narrower, the more relative certainty.
        </p>
        <p>
          Your feedback means a lot! Help us improve <i>WEDR</i> by <a href="https://www.facebook.com/groups/462464605395287">joining the <i>WEDR</i> community on Facebook</a> or <a href={decryptEmail('aW5mb0B3ZWRyLmFp')}>sending your feedback by email.</a>
        </p>
      </div>
    </div> 
    </>
  );
}